<template>
  <v-container>
    <h1 class="text-h3">{{ $t('scenarios') }}</h1>
    <p class="text-subtitle-1">{{ $t('scenariosDescription') }}</p>

    <v-btn v-if="isAdmin" color="primary" class="mb-6" @click="editScenario()">
      <v-icon left>fa-plus</v-icon>{{ $t('createScenario') }}
    </v-btn>

    <v-progress-linear :active="loading" :indeterminate="loading" />

    <v-chip class="my-2 appLegend" outlined label disabled>
      <v-icon left small>fas fa-mobile-alt</v-icon>
      {{ $t('scenariosMobileIconDescription') }}
    </v-chip>

    <v-row>
      <v-col cols="12" sm="6">
        <div>
          <v-snackbar v-model="updateSnackbar" timeout="2000" centered left color="redPrimary">
            {{ $t('scenarioUnableToUpdateSortOrder') }}
          </v-snackbar>
          <draggable
            :list="scenarios"
            :disabled="!isAdmin"
            class="list-group"
            ghost-class="ghost"
            handle=".handle"
            @start="dragging = true"
            @end="endMove"
          >
            <li
              v-for="(scenario, index) in scenarios"
              :key="index"
              class="list-group-item"
              :class="{ current: currentScenario.id === scenario.id }"
            >
              <v-row
                v-if="!scenario.hasValidTarget"
                class="u-bg-red-dark scenario-error-message p-0 mx-n5 mb-1"
              >
                <v-col class="u-color-white text-center pt-2">
                  {{ $t('scenarioInvalidTarget') }}
                </v-col>
              </v-row>
              <v-row no-gutters @click.stop="setCurrentScenario(scenario)">
                <v-col cols="1">
                  <v-tooltip right open-delay="300">
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-icon v-if="index < 3" small aria-hidden="false"
                          >fas fa-mobile-alt
                        </v-icon>
                      </div>
                    </template>
                    <span>{{ $t('scenariosMobileIconDescription') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col :class="{ hidden: !isAdmin }" cols="1">
                  <v-icon small class="handle">fas fa-align-justify handle" </v-icon>
                </v-col>
                <v-col cols="Auto" class="selectable">
                  <span>
                    <div
                      class="alertTypeCircle"
                      :class="[alertTypeClassName(scenario.alertType)]"
                    />
                    {{ scenario.name }}
                  </span>
                </v-col>
                <v-col cols="1" class="selectable">
                  <div v-if="scenario.attachments.length > 0">
                    <v-icon small>fas fa-paperclip</v-icon>
                  </div>
                </v-col>
                <v-col cols="1" class="selectable">
                  <div v-if="scenario.includePosition">
                    <v-icon small>fas fa-map-marker-alt</v-icon>
                  </div>
                </v-col>

                <v-col cols="1">
                  <v-btn
                    fab
                    height="25"
                    width="25"
                    x-small
                    elevation="1"
                    color="primary"
                    :disabled="!scenario.hasValidTarget"
                    @click="startScenario(scenario)"
                  >
                    <v-icon x-small class="play-button-symbol">fa-play</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </li>
          </draggable>
        </div>
      </v-col>

      <v-col v-if="currentScenario.id" cols="12" sm="6">
        <scenario
          :key="currentScenario.id"
          :data="currentScenario"
          :is-admin="isAdmin"
          :all-teams="allTeams"
          @edit="editScenario"
          @start="startScenario"
          @remove="deleteScenario"
        />
      </v-col>

      <v-dialog v-model="editScenarioDialog" max-width="500px" persistent>
        <edit-scenario
          :scenario="scenarioBeingEdited"
          :loading="loading"
          @cancel="cancelEditScenario"
          @save="saveScenario"
          @savedAlert="($alertData) => savedAlert($alertData)"
          @savedraft="saveScenarioDraft"
        />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import auth from '@/util/auth';
import draggable from 'vuedraggable';
import api from '@/util/api';
import EditScenario from '@/components/EditScenario';
import confirmDialog from '@/components/dialog/confirmDialog';
import router from '@/router';
import errorMessage from '@/util/errorMessage';
import getUserProfile from '@/util/getUserProfile';
import htmlMessageDialog from '@/components/dialog/htmlMessageDialog';
import Scenario from '@/views/scenarios/Scenario';
import { isAdmin } from '@/consts/roles';
import is from '@/lib/util/is';

export default {
  name: 'Scenarios',

  components: {
    draggable,
    EditScenario,
    Scenario,
  },

  data: () => ({
    alertTypeImage: {
      1: require('@/assets/images/alarm.svg'),
      2: require('@/assets/images/urgent-alert.svg'),
    },
    loading: false,

    scenarioId: '0',

    scenarios: [],

    editScenarioDialog: null,

    currentScenario: {},

    scenarioBeingEdited: {},

    events: [],

    updateSnackbar: false,

    isAdmin: false,
    allTeams: [],
  }),

  mounted() {
    if (is.number(this.$route.params.id)) {
      this.scenarioId = this.$route.params.id;
    }

    this.loadData();
    this.subscribeToSignalREvents(auth.getCustomerId());
    this.getAllTeams();
  },

  beforeDestroy() {
    this.events.forEach((event) => this.$signalR.off(event));
  },

  methods: {
    alertTypeClassName(alertType) {
      return (
        {
          1: 'alarm',
          2: 'alert',
        }[alertType] ?? 'alert'
      );
    },
    async getAllTeams() {
      const response = await api.getTeams();
      this.allTeams = response.teams;
    },
    async endMove() {
      let index = +1;
      let sortedScenarioIds = {};
      this.scenarios.forEach((scenario) => {
        sortedScenarioIds[scenario.id] = index;
        index++;
      });
      try {
        await api.updateScenarioSortIndex(sortedScenarioIds);
      } catch (error) {
        this.updateSnackbar = true;
      }
    },
    async setCurrentScenario(scenario) {
      if (scenario && this.currentScenario.id != scenario.id) {
        if (scenario.hasFullData) {
          this.currentScenario = JSON.parse(JSON.stringify(scenario));
        } else {
          this.currentScenario = await api.getScenario(scenario.id);
          Object.keys(scenario).forEach((key) => {
            scenario[key] = this.currentScenario[key];
          });
          scenario.hasFullData = true;
        }
      }
    },
    async loadData() {
      this.loading = true;

      const profile = await getUserProfile();

      if (!profile.features.find((feature) => feature.featureName === 'scenario')) {
        htmlMessageDialog(
          this.$t('featureUnavailable'),
          this.$t('featureUnavailableDescription', null, {
            mail: 'sales@teamalert.net',
            featureName: this.$t('scenario'),
          }),
          this.$t('ok'),
        )
          .open()
          .then(() => {
            router.replace('/');
          });
      } else {
        const role = profile.user.role;
        this.isAdmin = isAdmin(role);

        const res = await api.getAllScenarios();
        this.scenarios = res.scenarios;
        if (this.scenarios.length > 0) {
          let scenario;
          if (
            this.currentScenario &&
            (scenario = this.scenarios.find((s) => s.id === this.currentScenario.id))
          ) {
            this.currentScenario = {};
            await this.setCurrentScenario(scenario);
          } else {
            let currentIndex = this.scenarios.findIndex((a) => a.id.toString() === this.scenarioId);
            if (currentIndex === -1) {
              // If scenarioId is not found, set to first scenario
              currentIndex = 0;
            }
            await this.setCurrentScenario(this.scenarios[currentIndex]);
          }
        }
      }

      this.loading = false;
    },

    subscribeToSignalREvents(customerId) {
      this.events = [
        `newScenarioCustomerId:${customerId}`,
        `updatedScenarioCustomerId:${customerId}`,
        `deletedScenarioCustomerId:${customerId}`,
      ];

      const loadIfPresent = () => {
        this?.loadData();
      };
      this.events.forEach((event) => this.$signalR.on(event, loadIfPresent));
    },

    alertTypeStringValue(alertType) {
      return (
        {
          1: 'alarm',
          2: 'urgentAlert',
        }[alertType] ?? 'urgentAlert'
      );
    },

    cancelEditScenario() {
      this.editScenarioDialog = false;
      this.scenarioBeingEdited = {};
    },

    editScenario(scenario = {}) {
      // Find highest value for sortindex in scenarios
      let highestSortIndex = 0;
      this.scenarios.forEach((s) => {
        if (s.sortIndex > highestSortIndex) {
          highestSortIndex = s.sortIndex;
        }
      });
      scenario.sortIndex = highestSortIndex + 1;
      this.scenarioBeingEdited = scenario;
      this.editScenarioDialog = true;
    },

    savedAlert(alertData) {
      this.$set(this.currentScenario, 'alertData', alertData);
    },

    async saveScenario(scenario) {
      this.loading = true;

      try {
        if (scenario.id > 0) {
          // Check for changed properties, if changes, save
          const oldScenario = this.scenarios.find((s) => s.id === scenario.id);
          if (JSON.stringify(oldScenario) !== JSON.stringify(scenario)) {
            await api.updateScenario(scenario);
          }
        } else {
          // Find highest value for sortindex in scenarios
          let highestSortIndex = 0;
          this.scenarios.forEach((s) => {
            if (s.sortIndex > highestSortIndex) {
              highestSortIndex = s.sortIndex;
            }
          });
          scenario.sortIndex = highestSortIndex + 1;
          await api.createScenario(scenario);
        }
        this.setCurrentScenario(this.scenarios[this.scenarios.length - 1]);
        this.cancelEditScenario();
      } catch (error) {
        errorMessage(this.$t('saveScenarioError'), this.$t('saveScenarioErrorMsg'), error);
      }
      this.loading = false;
    },

    async saveScenarioDraft(scenario) {
      this.loading = true;

      try {
        scenario.sortIndex = this.scenarios?.length + 1;
        let createdScenario = await api.createScenario(scenario);
        this.scenarioBeingEdited = createdScenario;
        this.currentScenario = createdScenario;
      } catch (error) {
        errorMessage(this.$t('saveScenarioError'), this.$t('saveScenarioErrorMsg'), error);
      }
      this.loading = false;
    },

    deleteScenario(scenario) {
      confirmDialog(
        this.$t('deleteScenario'),
        this.$t('deleteScenarioConfirm'),
        this.$t('cancel'),
        this.$t('delete'),
      )
        .open()
        .then((confirmed) => {
          if (confirmed) {
            api.deleteScenario(scenario).then(() => {
              this.currentScenario = {};
            });
          }
        });
    },

    async startScenario(scenario) {
      const confirmed = await confirmDialog(
        this.$t('startScenario'),
        this.$t('startScenarioConfirm') + ' "' + scenario.name + '" ?',
        this.$t('cancel'),
        this.$t('start'),
      ).open();

      if (confirmed) {
        const alertData = scenario.alertData;

        try {
          const res = await api.sendScenario(scenario.id, alertData.title, alertData.body);

          if (res.alertId) {
            // Timeout to give the alert time to copy attachments
            setTimeout(() => {
              router.push(`/alerts/${res.alertId}`);
            }, 500);
          } else {
            errorMessage(this.$t('sendAlertError'), this.$t('sendAlertErrorMsg'), res);
          }
        } catch (error) {
          errorMessage(this.$t('sendAlertError'), this.$t('sendAlertErrorMsg'), error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/colors';

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin: 3px 0px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &.current {
    background-color: $colorGray200;
  }

  i {
    cursor: pointer;
  }
}
.handle {
  cursor: move;
  float: left;
  margin-top: 4px;
}
.selectable {
  cursor: pointer;
}
.hidden {
  display: none;
}
.appLegend {
  padding: 4px 26px;
  color: $colorGray800;
  opacity: 1;
}
.alertTypeCircle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px 0 0;
  border-radius: 50%;

  &.alert {
    background: linear-gradient(180deg, #eec437 0%, #efb145 100%);
    color: #553e07;
  }
  &.alarm {
    background: linear-gradient(180deg, #da2121 0%, #a91a10 100%);
    color: white;
  }
}
.play-button-symbol {
  margin: 1px 0 0 2px;
}
.scenario-error-message {
  line-height: 0;
  font-size: 0.6rem;
  letter-spacing: 0.05rem;
  height: 15px;
  border-radius: 1px;
}
</style>
